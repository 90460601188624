<template>
  <div class="auth-page d-flex flex-column justify-content-center align-items-center">
    <div>Please Wait</div>
    <b-spinner />
    <div v-if="!isAuthenticated">Connecting to Patreon...</div>
    <div v-if="isAuthenticated && !isSupporter">Verifying your pledge tier...</div>
    <div v-if="isAuthenticated && isSupporter">Taking you back to ANViL...</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PatreonAuth',
  created() {
    this.$store.dispatch('handleAuthentication', this.$route.query.code);
    setTimeout(() => this.$router.push({ path: '/' }), 30000);
  },
  computed: {
    ...mapState([
      'isAuthenticated',
      'isSupporter',
    ]),
  },
};
</script>

<style scoped lang="scss">
.auth-page {
  height: 100vh;
}
</style>
